<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
            v-model="searchForm.cardNo"
            placeholder="卡号后4位"
            clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-input
            v-model="searchForm.accountNumber"
            placeholder="商户卡ID"
            clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-input
            v-model="searchForm.description"
            placeholder="商户名"
            clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-select v-model="searchForm.type" placeholder="请选择开卡状态">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getUserList">搜索</el-button>
      </el-form-item>

<!--     <el-form-item>
          <el-button type="primary" @click="dialogVisible = true" v-if="hasAuth('shares:ceiling:save')">更新</el-button>
      </el-form-item>-->
<!--      <el-form-item>
        <el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
          <el-button type="danger" slot="reference" :disabled="delBtlStatu" v-if="hasAuth('sys:user:delete')">批量删除</el-button>
        </el-popconfirm>
      </el-form-item>-->

    </el-form>

    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        stripe
        @selection-change="handleSelectionChange">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>

      <el-table-column
          prop="id"
          label="id"
          width="70">
      </el-table-column>

      <el-table-column
          prop="oid"
          label="交易ID"
          width="170">
      </el-table-column>

      <el-table-column
          prop="cardId"
          label="商户卡ID"
          width="170">
      </el-table-column>

      <el-table-column
          prop="currency"
          label="国家"
          width="65">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.currency === 'USD'" type="primary">美国</el-tag>
          <el-tag size="small" v-else-if="scope.row.currency === 'HKD'" type="warning">香港</el-tag>
        </template>
      </el-table-column>
      <!---->
      <el-table-column
          prop="cardNo"
          label="卡号"
          width="170">
      </el-table-column>


      <el-table-column
          prop="amount"
          label="金额"
          width="140">
      </el-table-column>

      <el-table-column
          prop="currency"
          label="币种"
          width="100">
      </el-table-column>





      <el-table-column
          prop="debit"
          label="余额调整"
          width="100">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.debit === 'false'" type="success">加余额</el-tag>
          <el-tag size="small" v-else-if="scope.row.debit === 'true'" type="danger">减余额</el-tag>


        </template>
      </el-table-column>

<!--      <el-table-column
          prop="reason"
          label="交易错误代码"
          width="110">
      </el-table-column>-->

      <el-table-column
          prop="reason"
          label="交易错误代码"
          width="180">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.reason === '1016'" type="primary">余额不足</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1826'" type="primary">账单地址填写错误</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1825'" type="primary">CVV有误</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1874'" type="primary">卡片有效期有误</el-tag>

          <el-tag size="small" v-else-if="scope.row.reason === '1841'" type="primary">超出消费限额</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1864'" type="primary">地区限制</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1832'" type="primary">MCC限制</el-tag>


          <el-tag size="small" v-else-if="scope.row.reason === '1003'" type="primary">卡已冻结</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1813'" type="primary">持卡人被冻结</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1834'" type="primary">消费金额超过每周或每月限额</el-tag>

          <el-tag size="small" v-else-if="scope.row.reason === '1817'" type="primary">已达到使用限制</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1844'" type="primary">卡组织拒付</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '502'" type="primary">交易异常</el-tag>

          <el-tag size="small" v-else-if="scope.row.reason === '97'" type="交易超时">已达到使用限制</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1880'" type="primary">发卡行身份认证未通过</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1915'" type="primary">CVV不正确</el-tag>

          <el-tag size="small" v-else-if="scope.row.reason === '1901'" type="primary">卡未激活</el-tag>
          <el-tag size="small" v-else-if="scope.row.reason === '1806'" type="primary">未激活</el-tag>

        </template>
      </el-table-column>

<!--
      <el-table-column
          prop="mcc"
          label="消费商户类型"
          width="110">
      </el-table-column>

      <el-table-column
          prop="mid"
          label="消费商户id"
          width="110">
      </el-table-column>
-->

      <el-table-column
          prop="state"
          label="交易状态"
          width="100">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.state === 'CLEARED'" type="success">已完成</el-tag>
          <el-tag size="small" v-else-if="scope.row.state === 'PENDING'" type="danger">交易中</el-tag>
          <el-tag size="small" v-else-if="scope.row.state === 'DECLINED'" type="danger">已拒绝</el-tag>

        </template>
      </el-table-column>

      <el-table-column
          prop="type"
          label="交易类型"
          width="100">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.type === 'CARD_FUNDING'" type="success">转账</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === 'CONSUME'" type="danger">消费</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === 'REFUND'" type="danger">退款</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === 'FEE'" type="danger">手续费</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="description"
          label="商户名"
          width="200">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="交易时间"
          width="220">
      </el-table-column>


      <!--      <el-table-column
                prop="industry"
                label="所属行业"
                width="180">
            </el-table-column>-->


<!--      <el-table-column
          prop="icon"
          width="260px"
          label="操作">

        <template slot-scope="scope">

          <el-button type="text" @click="editHandle(scope.row.id)">编辑</el-button>
          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm title="这是一段内容确定删除吗？" @confirm="delHandle(scope.row.id)">
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>

        </template>
      </el-table-column>-->


    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[15, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total">
    </el-pagination>


    <!--新增对话框-->
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="999px"
        :before-close="handleClose">

      <el-form :model="editForm" :rules="editFormRules" ref="editForm">




        <el-input
            type="textarea"
            :rows="16"
            placeholder="卡片消费记录入库 请按照模板导入。"
            v-model="editForm.cardNb">
        </el-input>
<!--


        <el-form-item label="密码"  prop="password" label-width="100px">
          <el-input v-model="editForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密保"  prop="secretProtection" label-width="100px">
          <el-input v-model="editForm.secretProtection" autocomplete="off"></el-input>
        </el-form-item>
-->

        <!--				<el-form-item label="状态"  prop="statu" label-width="100px">
                  <el-radio-group v-model="editForm.statu">
                    <el-radio :label="0">禁用</el-radio>
                    <el-radio :label="1">正常</el-radio>
                  </el-radio-group>
                </el-form-item>-->

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
      </div>
    </el-dialog>




    <!-- 分配权限对话框 -->
    <el-dialog title="分配角色" :visible.sync="roleDialogFormVisible" width="600px">

      <el-form :model="roleForm">
        <el-tree
            :data="roleTreeData"
            show-checkbox
            ref="roleTree"
            :check-strictly=checkStrictly
            node-key="id"
            :default-expand-all=true
            :props="defaultProps">
        </el-tree>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogFormVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitRoleHandle('roleForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Role",
  data() {
    return {

      options: [{
        value: '',
        label: ''
      },{
        value: 'CARD_FUNDING',
        label: '转账'
      }, {
        value: 'CONSUME',
        label: '消费'
      }, {
        value: 'REFUND',
        label: '退款'
      }, {
        value: 'FEE',
        label: '手续费'
      }],
      searchForm: {},
      delBtlStatu: true,

      total: 0,
      size: 15,
      current: 1,

      dialogVisible: false,
      editForm: {

      },
      detailId: '',
      tableData: [],

      editFormRules: {
       /* accountNumber: [
          {required: true, message: '请输入iosId', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        secretProtection: [
          {required: true, message: '请输入密保', trigger: 'blur'}
        ]*/
      },

      multipleSelection: [],

      roleDialogFormVisible: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleForm: {},
      roleTreeData:  [],
      treeCheckedKeys: [],
      checkStrictly: true

    }
  },
  created() {
    this.getUserList()

    this.$axios.get("/sys/roleMGQZHP/list").then(res => {
      this.roleTreeData = res.data.data.records
    })
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      console.log("勾选")
      console.log(val)
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val
      this.getUserList()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },
    handleClose() {
      this.resetForm('editForm')
    },

    getUserList() {
      this.$axios.get("/card/obvious/listS", {
        params: {
          username: this.searchForm.accountNumber,
          cardNo: this.searchForm.cardNo,
          description: this.searchForm.description,
          type: this.searchForm.type,

          current: this.current,
          size: this.size
        }
      }).then(res => {
        this.tableData = res.data.data.records
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //alert((this.editForm.id?'update' : 'save'))
          this.$axios.post('/card/obvious/' + (this.editForm.id?'update' : 'save'), this.editForm)
              .then(res => {

                this.$message({
                  showClose: true,
                  message: '恭喜你，操作成功',
                  type: 'success',
                  onClose:() => {
                    this.getUserList()
                  }
                });

                this.dialogVisible = false
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get('/sale/account/info/' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    delHandle(id) {

      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
      //alert(ids);


      this.$axios.post("/sale/account/delete", ids).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });
      })
    },

    roleHandle (id) {
      this.roleDialogFormVisible = true

      this.$axios.get('/sys/userWQKKDUXHAL/info/' + id).then(res => {
        this.roleForm = res.data.data

        let roleIds = []
        res.data.data.sysRoles.forEach(row => {
          roleIds.push(row.id)
        })

        this.$refs.roleTree.setCheckedKeys(roleIds)
      })
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys()

      console.log(roleIds)

      this.$axios.post('/sys/userWQKKDUXHAL/role/' + this.roleForm.id, roleIds).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });

        this.roleDialogFormVisible = false
      })
    },
    repassHandle(id, username) {

      this.$confirm('将重置用户【' + username + '】的密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/sys/userWQKKDUXHAL/repass", id).then(res => {
          this.$message({
            showClose: true,
            message: '恭喜你，操作成功',
            type: 'success',
            onClose: () => {
            }
          });
        })
      })
    }
  }
}
</script>

<style scoped>

.el-pagination {
  float: right;
  margin-top: 22px;
}

</style>